import React from 'react';
import {
  Added,
  Link,
  PageWithSubNav,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="January 2024"
      subnav="release-notes">
      <div id="January2024" className={styles.release}>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Added>
                Links to the{' '}
                <Link href="https://uniform-web.storybook.hudltools.com/">
                  Version 5 API docs
                </Link>{' '}
                for several components.
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
